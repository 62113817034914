import { setLoginShow } from "actions/ui";
import classnames from "classnames";
import AssetHeader from "components/AssetHeader";
import AssetHeaderBackground from "components/AssetHeaderBackground";
import Button, { ButtonType } from "components/Button";
import CastLabel from "components/CastLabel";
import ErrorMessage from "components/ErrorMessage";
import FavoriteToggleButton from "components/FavoriteToggleButton";
import CatchupIcon from "components/Icons/CatchUp";
import Subscription from "components/Icons/Subscription";
import TrailerIcon from "components/Icons/Trailer";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import Player from "components/Player";
import Strate from "components/Strate";
import ThumbnailPoster from "components/ThumbnailPoster";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import { getFromLocal } from "helpers/localStorage";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";
import { default as StrateModel } from "web-api/models/Strate";

import style from "./style.module.css";

class PageAsset extends PageAbstract {
  state = {
    recommendationLoaded: false,
  };

  static async fetch(props) {
    const { dispatch, isConnected } = props;
    const assetId = props.match.params.id;
    await dispatch(TucanoActions.getAsset(assetId, getFromLocal("languageId")));
    if (isConnected) {
      dispatch(
        TucanoActions.getActiveAssets({
          languageId: getFromLocal("languageId"),
          allAssets: 1,
          count: 1000,
        })
      );
      dispatch(TucanoActions.getAssetScore(assetId));
      dispatch(TucanoActions.getFavorites(getFromLocal("languageId")));
    }
  }

  async componentDidMount() {
    const { dispatch, asset, isConnected } = this.props;
    let audioLang, subtitleLang;

    if (asset && asset.lang && isConnected) {
      audioLang = asset.lang[0].audio;
      subtitleLang = asset.lang[0].sub;

      await dispatch(
        TucanoActions.readAsset(asset.id, audioLang, subtitleLang)
      );
    }
    await PageAsset.fetch(this.props);
  }

  async componentDidUpdate(nextProps) {
    const id = parseInt(this.props.match.params.id);
    const { dispatch, asset } = this.props;
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    document.getElementsByTagName(
      "meta"
    )[6].content /* og:title */ = `${asset?.title} - ${consts.name}`;
    document.getElementsByTagName("meta")[8].content /* og:url */ =
      window.location.href;
    document.getElementsByTagName("meta")[9].content /* og:image */ =
      asset.getPosterImage(consts.image_hostname, {
        width: 1920,
        height: 1080,
        languageId: getFromLocal("languageId"),
      });
    if (
      (this.props.asset !== undefined &&
        this.props.asset.id &&
        this.props.asset.id !== id &&
        !this.props.isLoadingAsset) ||
      nextProps.isConnected !== this.props.isConnected
    ) {
      await PageAsset.fetch(this.props);
      this.setState({ recommendationLoaded: false });
    }
    if (asset) {
      document.title = `${asset.title} - ${consts.name}`;
      const synopsis = asset.synopsis;
      if (synopsis) {
        document.getElementsByTagName("meta")[3].content = asset.synopsis;
        document.getElementsByTagName("meta")[7].content /* og:description */ =
          asset.synopsis;
      } else {
        document.getElementsByTagName(
          "meta"
        )[3].content = `${asset.title} - ${consts.name}`;
        document.getElementsByTagName(
          "meta"
        )[7].content /* og:description */ = `${asset.title} - ${consts.name}`;
      }
      document.getElementsByTagName(
        "meta"
      )[4].content = `${asset.title}, ${asset.genre}, ${asset.cast}, ${asset.directors}`;
      document.getElementsByTagName("meta")[5].content = consts.name;
    }

    if (
      asset &&
      !asset.isCatchupAsset &&
      !this.state.recommendationLoaded &&
      consts.appModules.recommendation === true &&
      this.props.isConnected
    ) {
      this.setState({ recommendationLoaded: true });
      await dispatch(TucanoActions.getMonolistRecommendation(id, languageId));
      await dispatch(TucanoActions.getMultifacetRecommendation(id, languageId));
      document.title = `${asset.title} - ${consts.name}`;
      document.getElementsByTagName("meta")[3].content = asset.synopsis;
      document.getElementsByTagName("meta")[7].content /* og:description */ =
        asset.synopsis;
      document.getElementsByTagName(
        "meta"
      )[4].content = `${asset.title}, ${asset.genre}, ${asset.cast}, ${asset.directors}`;
      document.getElementsByTagName("meta")[5].content = consts.name;
    }
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetAsset());
  }

  handleChangeScore(score) {
    const { dispatch, isConnected } = this.props;
    if (isConnected) {
      if (score) {
        const assetId = this.props.asset.id;
        dispatch(TucanoActions.changeScoreAsset(assetId, score)).then(() => {
          dispatch(TucanoActions.getAssetScore(assetId));
        });
      }
    } else {
      this.props.history.push("?login=1");
    }
  }

  handleChangeFavoriteStatus() {
    const id = parseInt(this.props.match.params.id);
    const { dispatch, isAssetFavorite, isConnected } = this.props;
    if (isConnected) {
      if (isAssetFavorite) {
        dispatch(TucanoActions.deleteFavorite(id));
      } else {
        dispatch(TucanoActions.addFavorite(id));
      }
    } else {
      this.props.dispatch(setLoginShow(true));
    }
  }

  subscriptionModal() {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(this.props.asset);
    }
  }

  render() {
    const {
      isConnected,
      isLoadingAsset,
      asset,
      isAssetFavorite,
      viewport,
      trailer,
      t,
    } = this.props;
    const { showTrailer } = this.state;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    const direction = getFromLocal("layoutDirection");

    let assetInfos = [];
    if (asset) {
      assetInfos.push(asset.getReleaseYear());
      assetInfos.push(asset.getHumanDuration());
      assetInfos.push(asset.getGenre());
      assetInfos = assetInfos.filter(Boolean);
    }

    let isPhoneSize = false;
    if (thumbnailSize.name === "PHONE") {
      isPhoneSize = true;
    }

    // Recommendation
    let recommendationStrate = new StrateModel({
      title: "Suggestions",
      url: consts.routes.suggest.url.replace(":id", this.props.match.params.id),
    });

    const isLocalIsp = isConnectedFromLocalISP();

    return (
      <Fragment>
        {isLoadingAsset && <Loader centered={true} />}
        {!isLoadingAsset && asset && asset.id && (
          <div className={style.root}>
            <LinkBack
              rootClassName={classnames(
                direction.toLocaleLowerCase() === "rtl"
                  ? style.linkBackRTL
                  : style.linkBack
              )}
            />
            <AssetHeaderBackground
              item={asset}
              link={consts.routes.player.url.replace(":assetId", asset.getId())}
              url={asset.getPosterImage(consts.image_hostname, {
                width: 1920,
                height: 1080,
                languageId: getFromLocal("languageId"),
              })}
              isPhoneSize={isPhoneSize}
              subscriptionModal={this.subscriptionModal.bind(this)}
              rootClassName={
                !consts.showSliderGradient
                  ? style.assetHeaderWithoutGradient
                  : undefined
              }
            />
            <div className={style.contentContainer}>
              <AssetHeader
                title={asset.getTitle()}
                subtitle={assetInfos.join(" | ")}
                link={consts.routes.player.url.replace(
                  ":assetId",
                  asset.getId()
                )}
                thumbnailSize={thumbnailSize}
                item={asset}
                onRatingClick={this.handleChangeScore.bind(this)}
                isPhoneSize={isPhoneSize}
                subscriptionModal={this.subscriptionModal.bind(this)}
                buttons={
                  <Fragment>
                    {asset.getSeekTime() > 0 && (
                      <div className={style.startoverLink}>
                        {!isPhoneSize && (
                          <Link
                            className={style.playIconContainer}
                            to={`${consts.routes.player.url.replace(
                              ":assetId",
                              asset.getId()
                            )}?restart=true`}
                          >
                            <Button
                              rootClassName={style.startoverButton}
                              type={ButtonType.BORDERED}
                              icon={
                                <CatchupIcon className={style.startoverIcon} />
                              }
                            >
                              <span className={style.textButton}>
                                <Trans>Restart</Trans>
                              </span>
                            </Button>
                          </Link>
                        )}
                        {isPhoneSize && (
                          <div className={style.round}>
                            <Button
                              rootClassName={style.roundButton}
                              type={ButtonType.BORDERED}
                              icon={
                                <CatchupIcon className={style.trailerIcon} />
                              }
                              href={`${consts.routes.player.url.replace(
                                ":assetId",
                                asset.getId()
                              )}?restart=true`}
                            />
                            <div className={style.textButton}>
                              <Trans>Restart</Trans>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <FavoriteToggleButton
                      onFavoriteClick={this.handleChangeFavoriteStatus.bind(
                        this
                      )}
                      isAssetFavorite={isAssetFavorite}
                      rootClassName={style.favoriteIcon}
                      isPhoneSize={isPhoneSize}
                    />
                    {asset && asset.getTrailerId() && (
                      <div className={style.trailerLink}>
                        {!isPhoneSize && (
                          <Link
                            className={style.playIconContainer}
                            to={consts.routes.playerTrailer.url.replace(
                              ":assetId",
                              asset.getId()
                            )}
                          >
                            <Button
                              rootClassName={style.roundButton}
                              type={ButtonType.BORDERED}
                              icon={
                                <TrailerIcon className={style.trailerIcon} />
                              }
                            >
                              <span className={style.textButton}>
                                <Trans>Watch trailer</Trans>
                              </span>
                            </Button>
                          </Link>
                        )}
                        {isPhoneSize && (
                          <div className={style.round}>
                            <Button
                              rootClassName={style.roundButton}
                              type={ButtonType.BORDERED}
                              icon={
                                <TrailerIcon className={style.trailerIcon} />
                              }
                              href={consts.routes.playerTrailer.url.replace(
                                ":assetId",
                                asset.getId()
                              )}
                            />
                            <div className={style.textButton}>
                              <Trans>Watch trailer</Trans>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {consts.padlocks === true &&
                      isConnected === true &&
                      !asset.userHasAccess && (
                        <Fragment>
                          {!isPhoneSize && (
                            <Button
                              rootClassName={style.subscriptionButton}
                              type={ButtonType.NORMAL}
                              icon={
                                <Subscription
                                  className={style.subscriptionIcon}
                                />
                              }
                              onClick={this.subscriptionModal.bind(this)}
                            >
                              <span className={style.textButton}>
                                <Trans>Subscription options</Trans>
                              </span>
                            </Button>
                          )}
                          {isPhoneSize && (
                            <div className={style.round}>
                              <Button
                                rootClassName={style.roundButton}
                                type={ButtonType.NORMAL}
                                icon={
                                  <Subscription
                                    className={style.subscriptionIcon}
                                  />
                                }
                                onClick={this.subscriptionModal.bind(this)}
                              ></Button>
                              <span
                                className={classnames(
                                  style.textButton,
                                  style.textSubscriptionButton
                                )}
                              >
                                <Trans>Subscription options</Trans>
                              </span>
                            </div>
                          )}
                        </Fragment>
                      )}
                  </Fragment>
                }
              />
              <div
                className={classnames(style.presentationContainer, {
                  [style.withFooter]: isLocalIsp,
                  [style.withoutFooter]: !isLocalIsp,
                })}
              >
                <div className={style.descriptionContainer}>
                  {asset.getSynopsis() !== null &&
                    asset.getSynopsis() !== undefined && (
                      <div className={style.synopsisContainer}>
                        <h2 className={style.containerTitle}>
                          <Trans>SYNOPSIS</Trans>
                        </h2>
                        <p className={style.containerInformation}>
                          {asset.getSynopsis()}
                        </p>
                      </div>
                    )}
                  {/* CASTING */}
                  {asset &&
                    asset.getCast() !== undefined &&
                    asset.getCast().length > 0 && (
                      <Fragment>
                        <div className={style.horizontalLine} />
                        <div className={style.castContainer}>
                          <Fragment>
                            <h2 className={style.containerTitle}>
                              <Trans>CAST</Trans>
                            </h2>
                            {asset.getCastFirstRoles() &&
                              asset.getCastFirstRoles().map((item, index) => (
                                <CastLabel key={index} asset={asset}>
                                  {item}
                                </CastLabel>
                              ))}
                          </Fragment>
                          <div className={style.castContainer}>
                            {asset.getCastSecondRoles()}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  {/* DIRECTORS */}
                  {asset &&
                    asset.getDirectors() !== undefined &&
                    asset.getDirectors().length > 0 &&
                    asset.getDirectors() !== "N/A" && (
                      <Fragment>
                        <div className={style.horizontalLine} />
                        <div className={style.directorContainer}>
                          <h2 className={style.containerTitle}>
                            <Trans>DIRECTOR(S)</Trans>
                          </h2>
                          <p className={style.containerInformation}>
                            {asset.getDirectors()}
                          </p>
                        </div>
                      </Fragment>
                    )}
                </div>
                {/* RECOMMENDATION */}
                {asset && asset.getMonolistRecommendation().length > 0 && (
                  <Fragment>
                    <div className={style.horizontalLine} />
                    <Strate
                      item={recommendationStrate}
                      seeAll={asset.getMultifacetRecommendation().length > 0}
                    >
                      {asset.getMonolistRecommendation().map((item, index) => {
                        return (
                          <ThumbnailPoster
                            key={index}
                            asset={item}
                            size={thumbnailSize}
                            subscriptionModal={this.subscriptionModal.bind(
                              this
                            )}
                          />
                        );
                      })}
                    </Strate>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}
        {asset && asset.id && showTrailer && (
          <div className={style.trailerPlayer}>
            <Player
              isConnected={isConnected}
              isTrailer={true}
              closeAction={() => {
                this.setState({ showTrailer: false });
              }}
              assetInfo={{
                title: `${t("Trailer")} - ${asset.getTitle()}`,
              }}
              url={trailer.data.url}
              subscriptionModal={this.subscriptionModal.bind(this)}
            />
          </div>
        )}
        {(!asset || !asset.id) && (
          <ErrorMessage
            rootClassName={style.errorMessage}
            message={t("Asset not found")}
          />
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state, _props) => {
    const catalog = TucanoSelectors.getCatalog(state, 2);
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      isLoadingAsset: state.content.asset.loading,
      viewport: state.ui.viewport,
      asset: TucanoSelectors.getAsset(state),
      trailer: TucanoSelectors.getAssetTrailer(state),
      breadcrumb: TucanoSelectors.getAssetBreadcrumb(state, catalog),
      isAssetFavorite: TucanoSelectors.isAssetFavorite(state),
      deviceAuthToken: state.session.deviceAuthToken !== undefined,
    };
  }),
  withTranslation()
)(PageAsset);
