import classnames from "classnames";
import Dropdown from "components/Dropdown";
import EpgInfos from "components/EpgInfos";
import FavoriteAdd from "components/Icons/FavoriteAdd";
import FavoriteRemove from "components/Icons/FavoriteRemove";
import FavoritesFilled from "components/Icons/FavoritesFilled";
import FreeToAir from "components/Icons/FreeToAir";
import Kebab from "components/Icons/Kebab";
import Live from "components/Icons/Live";
import PadlockLock from "components/Icons/PadlockLock";
import Play from "components/Icons/Play";
import PlayLock from "components/Icons/PlayLock";
import PlayUnlock from "components/Icons/PlayUnlock";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import style from "./style.module.css";

export default class ThumbnailLive extends Component {
  subscriptionModal() {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(this.props.channel);
    }
  }
  openMenuContext(event) {
    if (this.props.openMenuContext) {
      this.props.openMenuContext(
        this.props.channel,
        event.clientX,
        event.clientY
      );
    }
  }

  handleMenuClick(event) {
    const { channel } = this.props;
    if (event.actionName === "favorite") {
      this.props.AddToFavorite(channel.getId());
    } else if (event.actionName === "live") {
      this.props.history.replace(
        consts.routes.playerlive.url.replace(":channelId", channel.getId())
      );
    }
  }

  renderDropdown() {
    const filterRow = this.props.isFavorite
      ? {
          label: "Remove from favorite",
          actionName: "favorite",
          icon: <FavoriteRemove />,
        }
      : {
          label: "Add to favorite",
          actionName: "favorite",
          icon: <FavoriteAdd />,
        };
    return (
      <span className={style.kebab}>
        <Dropdown
          iconOnClick={
            <Kebab className={style.kebabIcon} height={25} width={25} />
          }
          selfClosing={true}
          handleClick={this.handleMenuClick.bind(this)}
          removeBackgroundColor={true}
          disableActiveElement={true}
          data={[
            filterRow,
            {
              label: "Watch live",
              actionName: "live",
              icon: <Live />,
            },
          ]}
        />
      </span>
    );
  }
  render() {
    const {
      epg,
      channel,
      isConnected,
      fta,
      withoutDimensions = false,
    } = this.props;
    let url = "";
    let imageUrl = "#";
    if (epg && Object.keys(epg).length !== 0) {
      url = consts.routes.epgAsset.url.replace(":id", epg.getEpgId());
      imageUrl = epg.getImage(consts.image_hostname, {
        width: 764,
        height: 430,
        languageId: getFromLocal("languageId"),
      });
    }
    return (
      <Fragment>
        {url === "" ? (
          <div>
            {consts.padlocks === true &&
            isConnected === true &&
            !channel.userHasAccess ? (
              <div onClick={this.subscriptionModal.bind(this)}>
                {this.renderDropdown()}
                {this.props.isFavorite && (
                  <FavoritesFilled
                    className={style.favorites}
                    height={11}
                    width={12}
                  />
                )}
                <div className={style.image}>
                  <Image
                    height={232}
                    src={imageUrl}
                    width={415}
                    ratio={ImageRatio.COVER}
                    placeholder={PlaceholderType.PRIMARY}
                  />
                  <PlayLock
                    className={classnames(style.playIcons, style.playLockIcon)}
                  />
                  <PlayUnlock
                    className={classnames(
                      style.playIcons,
                      style.playUnlockIcon
                    )}
                  />
                  <div className={style.padlockContainer}>
                    <div className={style.padlockIconContainer}>
                      <PadlockLock className={style.padlockLockIcon} />
                    </div>
                  </div>
                </div>
                <div className={style.context}>
                  <EpgInfos
                    isConnected={isConnected}
                    showLock={false}
                    epg={epg}
                    channel={channel}
                    rootClassName={style.epgLive}
                  />
                </div>
              </div>
            ) : (
              <Link
                className={style.link}
                to={consts.routes.playerlive.url.replace(
                  ":channelId",
                  channel.getId()
                )}
              >
                {this.renderDropdown()}
                {this.props.isFavorite && (
                  <FavoritesFilled
                    className={style.favorites}
                    height={11}
                    width={12}
                  />
                )}
                <div className={style.image}>
                  <Image
                    height={232}
                    src={imageUrl}
                    width={415}
                    ratio={ImageRatio.COVER}
                    placeholder={PlaceholderType.PRIMARY}
                  />
                  <Play className={style.playIcon} />
                  {fta && !isConnected && (
                    <div className={style.ftaIconContainer}>
                      <FreeToAir className={style.fta} />
                    </div>
                  )}
                </div>
                <div className={style.context}>
                  <EpgInfos
                    isConnected={isConnected}
                    showLock={false}
                    epg={epg}
                    channel={channel}
                    rootClassName={style.epgLive}
                  />
                </div>
              </Link>
            )}
          </div>
        ) : (
          <div>
            {consts.padlocks === true &&
            isConnected === true &&
            !channel.userHasAccess ? (
              <div
                onClick={this.subscriptionModal.bind(this)}
                className={style.container}
              >
                {this.renderDropdown()}
                {this.props.isFavorite && (
                  <FavoritesFilled
                    className={style.favorites}
                    height={11}
                    width={12}
                  />
                )}
                <div className={style.image}>
                  <Image
                    height={232}
                    src={imageUrl}
                    width={415}
                    ratio={ImageRatio.COVER}
                    placeholder={PlaceholderType.PRIMARY}
                  />
                  <PlayLock
                    className={classnames(style.playIcons, style.playLockIcon)}
                  />
                  <PlayUnlock
                    className={classnames(
                      style.playIcons,
                      style.playUnlockIcon
                    )}
                  />
                  <div className={style.padlockContainer}>
                    <div className={style.padlockIconContainer}>
                      <PadlockLock className={style.padlockLockIcon} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Link
                to={consts.routes.playerlive.url.replace(
                  ":channelId",
                  channel.getId()
                )}
                className={style.link}
              >
                {this.renderDropdown()}
                {this.props.isFavorite && (
                  <FavoritesFilled
                    className={style.favorites}
                    height={11}
                    width={12}
                  />
                )}
                <div className={style.image}>
                  <Image
                    height={232}
                    src={imageUrl}
                    width={415}
                    ratio={ImageRatio.COVER}
                    placeholder={PlaceholderType.PRIMARY}
                  />
                  <Play className={style.playIcon} />
                  {fta && !isConnected && (
                    <div className={style.ftaIconContainer}>
                      <FreeToAir className={style.fta} />
                    </div>
                  )}
                </div>
              </Link>
            )}
            <Link to={url} className={style.container}>
              <div className={style.context}>
                <EpgInfos
                  isConnected={isConnected}
                  showLock={false}
                  epg={epg}
                  channel={channel}
                  rootClassName={style.epgLive}
                  withoutDimensions={withoutDimensions}
                />
              </div>
            </Link>
          </div>
        )}
      </Fragment>
    );
  }
}

ThumbnailLive.defaultProps = {
  epg: {},
  channel: {},
  isConnected: false,
};

ThumbnailLive.propTypes = {
  epg: PropTypes.object,
  channel: PropTypes.object,
  isConnected: PropTypes.bool,
};
