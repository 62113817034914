import classnames from "classnames";
import Play from "components/Icons/Play";
import PlayLock from "components/Icons/PlayLock";
import PlayUnlock from "components/Icons/PlayUnlock";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import ProgressBar from "components/ProgressBar";
import consts from "consts/consts";
import { isPast } from "date-fns";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import style from "./style.module.css";

export default class EPGGridImage extends Component {
  subscriptionModal() {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(this.props.channel);
    }
  }

  onClick(url, event) {
    if (this.props.onClick) {
      this.props.onClick(event, url);
    }
  }

  render() {
    const { epg, width, height, channel, isConnected, hover } = this.props;

    const languageId = getFromLocal("languageId") ?? consts.languageId;
    let url = "";
    const now = new Date().getTime();
    let displayPlayIcon = false;

    if (epg) {
      if (
        (channel.isCatchupChannel === true ||
          (isConnected && !channel.userHasAccess)) &&
        epg.getStartTime().getTime() > now - channel.getCatchupValue() &&
        epg.isCatchupAvailable() &&
        isPast(epg.getEndTime())
      ) {
        url = consts.routes.movieAsset.url.replace(
          ":id",
          epg.isCatchupAvailable()
        );
        displayPlayIcon = true;
      } else {
        url = consts.routes.epgAsset.url.replace(":id", epg.getEpgId());
      }
    }
    return (
      <div className={classnames(style.image, { [style.imageOver]: hover })}>
        <Link to={url} onClick={this.onClick.bind(this, url)}>
          <Image
            src={epg.getImage(consts.image_hostname, {
              width: 340,
              height: 191,
              languageId: languageId,
            })}
            height={height}
            width={width}
            ratio={ImageRatio.COVER}
            placeholder={PlaceholderType.PRIMARY}
            rootClassName={classnames({ [style.hover]: hover })}
          />
        </Link>
        {consts.padlocks === true &&
          isConnected === true &&
          !channel.userHasAccess &&
          (epg.isLive() || (!epg.isLive() && displayPlayIcon)) && (
            <div
              onClick={this.subscriptionModal.bind(this)}
              className={style.lockContainer}
            >
              <PlayLock
                className={classnames(style.playIcons, style.playLockIcon)}
              />
              <PlayUnlock
                className={classnames(style.playIcons, style.playUnlockIcon)}
              />
            </div>
          )}
        {epg.isLive() &&
          (channel.userHasAccess ||
            consts.padlocks === false ||
            !isConnected) && (
            <Link
              to={consts.routes.playerlive.url.replace(
                ":channelId",
                epg.getChannelId()
              )}
              onClick={this.onClick.bind(
                this,
                consts.routes.playerlive.url.replace(
                  ":channelId",
                  epg.getChannelId()
                )
              )}
            >
              <Play className={style.playIcon} />
            </Link>
          )}
        {!epg.isLive() &&
          displayPlayIcon &&
          (channel.userHasAccess ||
            consts.padlocks === false ||
            !isConnected) && (
            <Link
              to={consts.routes.player.url.replace(
                ":assetId",
                epg.isCatchupAvailable()
              )}
              onClick={this.onClick.bind(
                this,
                consts.routes.player.url.replace(
                  ":assetId",
                  epg.isCatchupAvailable()
                )
              )}
            >
              <Play className={style.playIcon} />
            </Link>
          )}
        {epg.isLive() && (
          <ProgressBar
            position={epg.getProgress()}
            rootClassName={style.progressBar}
          />
        )}
      </div>
    );
  }
}

EPGGridImage.defaultProps = {
  isConnected: false,
};

EPGGridImage.propTypes = {
  isConnected: PropTypes.bool,
};
