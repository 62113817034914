import actionTypes from "consts/actionTypes";
import responsiveConf from "consts/responsive";

const initialState = {
  isApplicationReady: undefined,
  EPGDate: new Date(),
  viewport: {
    width: 0,
    height: 0,
    type: "PHONE",
  },
  isProfileFormExist: undefined,
  toasts: [],
  livePosition: null,
  subtitlePreference: [],
  audioPreference: [],
  subtitlePreferenceRef: undefined,
  audioPreferenceRef: undefined,
  channelFilter: [],
  channelSort: [],
  discoverFilter: null,
  discoverSort: null,
  searchTerm: "",
  directArrayForFavorites: null,
  serverError: false,
  historyLink: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_APPLICATION_READY:
      return {
        ...state,
        isApplicationReady: action.payload,
      };
    case actionTypes.SET_PROFILE_FORM_VISIBILITY:
      return {
        ...state,
        isProfileFormExist: action.payload,
      };
    case actionTypes.SET_APPLICATION_VIEWPORT:
      // eslint-disable-next-line no-case-declarations
      const width = action.payload.width;
      // eslint-disable-next-line no-case-declarations
      const type = responsiveConf.find((item) => {
        return item.min <= width && width <= item.max;
      });
      return {
        ...state,
        viewport: { ...action.payload, type: type.name },
      };
    case actionTypes.SET_APPLICATION_PREVIOUS_ROUTE:
      return {
        ...state,
        previousRoute: action.payload,
      };
    case actionTypes.SET_APPLICATION_PREVIOUS_STABLE_ROUTE:
      return {
        ...state,
        previousStableRoute: action.payload,
      };
    case actionTypes.SET_EPG_DATE:
      return {
        ...state,
        EPGDate: action.payload,
      };
    case actionTypes.RESET_EPG_DATE:
      return {
        ...state,
        EPGDate: new Date(),
      };
    case actionTypes.ADD_TOAST:
      return {
        ...state,
        toasts: [action.payload, ...state.toasts],
      };
    case actionTypes.REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload),
      };
    case actionTypes.SET_MENU_STATUS:
      return {
        ...state,
        menuOpen: action.payload,
      };
    case actionTypes.SET_LOGIN_STATUS:
      return {
        ...state,
        loginShown: action.payload,
      };
    case actionTypes.SET_ERROR_SHOW:
      return {
        ...state,
        showApiError: action.payload,
      };
    case actionTypes.SET_NEW_POSITION:
      return {
        ...state,
        newPosition: action.payload,
      };
    case actionTypes.SET_PAUSE_POSITION:
      return {
        ...state,
        pausePosition: action.payload,
      };
    case actionTypes.SET_LIVE_POSITION:
      return {
        ...state,
        livePosition: action.payload,
      };
    case actionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case actionTypes.SET_AUDIO_PREFERENCE:
      // eslint-disable-next-line no-case-declarations
      let initialAudio = state.audioPreference;
      // eslint-disable-next-line no-case-declarations
      let found = false;
      for (let i = 0; i < initialAudio.length; i++) {
        if (initialAudio[i].profileId === action.payload.profileId) {
          found = true;
          initialAudio[i].audio = action.payload.audio;
        }
      }
      if (!found) {
        initialAudio.push(action.payload);
      }
      return {
        ...state,
        audioPreference: initialAudio,
        audioPreferenceRef: action.payload?.audio?.reference,
      };
    case actionTypes.SET_CHANNEL_FILTER:
      if (action.connected) {
        //set filter per profile
        let initialFilter = state.channelFilter || [];
        found = false;
        for (let i = 0; i < initialFilter?.length; i++) {
          if (initialFilter[i].profile === action.payload.profile) {
            found = true;
            initialFilter[i].value = action.payload.value;
          }
        }
        if (!found) {
          initialFilter?.push(action.payload);
        }
        return {
          ...state,
          channelFilter: initialFilter,
        };
      } else {
        //set general filter
        return {
          ...state,
          discoverFilter: action.payload.value,
        };
      }
    case actionTypes.SET_CHANNEL_SORT:
      if (action.connected) {
        //set filter per profile
        let initialCriteria = state.channelSort || [];
        found = false;
        for (let i = 0; i < initialCriteria?.length; i++) {
          if (initialCriteria[i].profile === action.payload.profile) {
            found = true;
            initialCriteria[i].value = action.payload.value;
          }
        }
        if (!found) {
          initialCriteria?.push(action.payload);
        }
        return {
          ...state,
          channelSort: initialCriteria,
        };
      } else {
        //set general filter
        return {
          ...state,
          discoverSort: action.payload.value,
        };
      }
    case actionTypes.SET_FAVORITES_DIRECT_ARRAY:
      return {
        ...state,
        directArrayForFavorites: action.payload,
      };
    case actionTypes.SET_SUBTITLE_PREFERENCE:
      // eslint-disable-next-line no-case-declarations
      let initialSub = state.subtitlePreference;
      // eslint-disable-next-line no-case-declarations
      let subtitlePreferenceRef = action.payload?.sub?.reference;
      found = false;
      for (let i = 0; i < initialSub.length; i++) {
        if (initialSub[i].profileId === action.payload.profileId) {
          found = true;
          initialSub[i].sub = action.payload.sub;
        }
      }
      if (!found) {
        initialSub.push(action.payload);
      }
      return {
        ...state,
        subtitlePreference: initialSub,
        subtitlePreferenceRef: subtitlePreferenceRef,
      };
    case actionTypes.SET_SERVER_ERROR:
      return {
        ...state,
        serverError: true,
      };
    case actionTypes.RESET_SERVER_ERROR:
      return {
        ...state,
        serverError: false,
      };
    case actionTypes.RESET_CHANNEL_FILTER:
      return {
        ...state,
        channelFilter: [],
        channelSort: [],
      };
    case actionTypes.SET_APP_HISTORY_LINK:
      return {
        ...state,
        historyLink: action.link,
      };
    case actionTypes.RESET_APP_HISTORY_LINK: {
      return {
        ...state,
        historyLink: "",
      };
    }
    case actionTypes.REMOVE_ALL_TOAST:
      return {
        ...state,
        toasts: [],
      };

    default:
      return state;
  }
};
