import classnames from "classnames";
import AssetHeader from "components/AssetHeader";
import AssetHeaderBackground from "components/AssetHeaderBackground";
import Button, { ButtonType } from "components/Button";
import CastLabel from "components/CastLabel";
import Dropdown from "components/Dropdown";
import FavoriteToggleButton from "components/FavoriteToggleButton";
import CatchupIcon from "components/Icons/CatchUp";
import Subscription from "components/Icons/Subscription";
import TrailerIcon from "components/Icons/Trailer";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import SeasonEpisode from "components/SeasonEpisode";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import { getFromLocal } from "helpers/localStorage";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageAssetTVShow extends PageAbstract {
  state = {
    activeSeason: 0,
    currentEpisodeItem: undefined,
    TVShowTrailer: undefined,
    TVShowCastingFirstRoles: undefined,
    TVShowCastingSecondRoles: undefined,
    TVShowDirector: undefined,
    firstEpisodeID: undefined,
    tvShowInfos: undefined,
  };

  static fetch(dispatch, isConnected, params) {
    const id = params.id;
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    dispatch(TucanoActions.getTVShow(id, languageId)).then((result) => {
      if (result) {
        result.items.forEach((item, index) => {
          //TODO to check why we have all those calls
          const seasonId = item.payload.idCatalog;
          dispatch(
            TucanoActions.getTVShowEpisodes(seasonId, index, languageId)
          );
        });
      }
    });

    if (isConnected) {
      dispatch(
        TucanoActions.getActiveAssets({
          languageId: languageId,
          allAssets: 1,
          count: 1000,
          contentTypeFilter: "series+episode",
        })
      );

      dispatch(TucanoActions.getAssetScore(id, true));
      dispatch(TucanoActions.getFavorites(languageId));
    }
  }

  handleChangeFavoriteStatus() {
    let id = parseInt(this.props.match.params.id);
    const { dispatch, isAssetFavorite, isConnected } = this.props;
    if (isConnected) {
      if (isAssetFavorite) {
        dispatch(TucanoActions.deleteFavorite(id, true, true));
      } else {
        dispatch(TucanoActions.addFavorite(id, true, true));
      }
    } else {
      this.props.history.push("?login=1");
    }
  }

  handleChangeScore(score) {
    const { dispatch, isConnected } = this.props;
    if (isConnected) {
      if (score) {
        const assetId = this.props.tvshow.id;
        dispatch(TucanoActions.changeScoreAsset(assetId, score, true)).then(
          () => {
            dispatch(TucanoActions.getAssetScore(assetId, true));
          }
        );
      }
    } else {
      this.props.history.push("?login=1");
    }
  }

  componentDidMount() {
    PageAssetTVShow.fetch(
      this.props.dispatch,
      this.props.isConnected,
      this.props.match.params
    );
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetAsset());
  }

  componentDidUpdate(prevProps) {
    const { tvshow, seasons, activeAssets, t, match, dispatch, isConnected } =
      this.props;
    if (match.params.id !== prevProps.match.params.id) {
      PageAssetTVShow.fetch(dispatch, isConnected, match.params);
    }

    if (prevProps && prevProps.seasons !== seasons) {
      if (tvshow && seasons && seasons.length > 0) {
        const assets = seasons[0].getAssets();
        if (assets && assets.length > 0) {
          document.title = `${tvshow.title} - ${consts.name}`;
          document.getElementsByTagName("meta")[3].content = tvshow.synopsis;
          document.getElementsByTagName("meta")[4].content = `${
            tvshow.title
          }, ${assets[0].getGenre()}, ${assets[0].getCastFirstRoles()}, ${assets[0].getCastSecondRoles()}, ${assets[0].getDirectors()}`;
          document.getElementsByTagName("meta")[5].content = consts.name;
          document.getElementsByTagName(
            "meta"
          )[6].content /* og:title */ = `${tvshow.title} - ${consts.name}`;
          document.getElementsByTagName(
            "meta"
          )[7].content /* og:description */ = tvshow.synopsis;
          document.getElementsByTagName("meta")[8].content /* og:url */ =
            window.location.href;
          document.getElementsByTagName("meta")[9].content /* og:image */ =
            tvshow.getPosterImage(consts.image_hostname, {
              width: 1920,
              height: 1080,
              languageId: getFromLocal("languageId"),
            });
          let tvShowInfos = [];
          tvShowInfos.push(assets[0].getReleaseYear());
          tvShowInfos.push(t("season", { count: tvshow.getSeasonCount() }));
          // if (assets[0].getDuration()) tvShowInfos.push(`${assets[0].getDuration()}MIN`);
          // if (assets[0].getMoralityLevel()) tvShowInfos.push(`${assets[0].getMoralityLevel()}+`);
          tvShowInfos.push(assets[0].getGenre());
          tvShowInfos = tvShowInfos.filter(Boolean);

          this.setState({
            currentEpisodeItem: assets[0],
            TVShowTrailer: assets[0].getTrailerId(),
            TVShowCastingFirstRoles: assets[0].getCastFirstRoles(),
            TVShowCastingSecondRoles: assets[0].getCastSecondRoles(),
            TVShowDirector: assets[0].getDirectors(),
            firstEpisodeID: assets[0].getId(),
            tvShowInfos: tvShowInfos,
          });
        }
      }
    }
    if (activeAssets !== undefined) {
      if (
        prevProps &&
        (JSON.stringify(prevProps.activeAssets) !==
          JSON.stringify(activeAssets) ||
          seasons?.length !== prevProps?.seasons?.length) &&
        activeAssets.length > 0
      ) {
        const id = parseInt(this.props.match.params.id);
        activeAssets.forEach((item) => {
          if (
            item.getParentId() &&
            item.isTVShow() &&
            id === parseInt(item.getParentId())
          ) {
            let tvShowInfos = [];
            tvShowInfos.push(item.getReleaseYear());
            // tvShowInfos.push(t("season", { count: item.getSeasonCount() }));

            if (item.getDuration())
              tvShowInfos.push(`${item.getDuration()}MIN`);
            tvShowInfos.push(item.getGenre());
            tvShowInfos = tvShowInfos.filter(Boolean);
            const activeSeason = seasons?.findIndex(
              (season) => season.seasonNumber === item.getSeasonNumber()
            );
            this.setState({
              currentEpisodeItem: item,
              TVShowTrailer: item.getTrailerId(),
              TVShowCastingFirstRoles: item.getCastFirstRoles(),
              TVShowCastingSecondRoles: item.getCastSecondRoles(),
              TVShowDirector: item.getDirectors(),
              firstEpisodeID: item.getId(),
              tvShowInfos: tvShowInfos,
              activeSeason: activeSeason === -1 ? 0 : activeSeason,
            });
          }
        });
      }
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  onRestartPlayer = (currentEpisodeID) => {
    if (this.state.currentEpisodeItem?.userHasAccess) {
      const { history, dispatch } = this.props;
      dispatch(TucanoActions.toRestartAssestVideo());
      history.push(`/player/${currentEpisodeID}`);
    }
  };

  render() {
    const {
      isConnected,
      isLoading,
      tvshow,
      seasons,
      isAssetFavorite,
      viewport,
    } = this.props;
    //const showOrHideSubscription = false;
    const {
      activeSeason,
      currentEpisodeItem,
      TVShowTrailer,
      TVShowCastingFirstRoles,
      TVShowCastingSecondRoles,
      TVShowDirector,
      firstEpisodeID,
      tvShowInfos,
    } = this.state;
    let seasonsFormatted = seasons
      ? seasons.map((item, index) => {
          return { index, label: item.name };
        })
      : [];
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });

    let isPhoneSize;
    if (thumbnailSize.name === "PHONE") {
      isPhoneSize = true;
    }
    const direction = getFromLocal("layoutDirection");
    const isLocalIsp = isConnectedFromLocalISP();
    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && tvshow && (
          <div
            className={classnames(style.root, {
              [style.withFooter]: isLocalIsp,
              [style.withoutFooter]: !isLocalIsp,
            })}
          >
            <LinkBack
              rootClassName={classnames(
                direction.toLocaleLowerCase() === "rtl"
                  ? style.linkBackRTL
                  : style.linkBack
              )}
            />
            <AssetHeaderBackground
              item={tvshow}
              link={consts.routes.player.url.replace(
                ":assetId",
                firstEpisodeID
              )}
              url={tvshow.getPosterImage(consts.image_hostname, {
                width: 1920,
                height: 1080,
                languageId: getFromLocal("languageId"),
              })}
              isPhoneSize={isPhoneSize}
              subscriptionModal={this.subscriptionModal.bind(
                this,
                currentEpisodeItem
              )}
              rootClassName={
                !consts.showSliderGradient
                  ? style.assetHeaderWithoutGradient
                  : undefined
              }
            />
            <div className={style.contentContainer}>
              <AssetHeader
                title={tvshow.getTitle()}
                subtitle={tvShowInfos ? tvShowInfos.join(" | ") : ""}
                thumbnailSize={thumbnailSize}
                item={tvshow}
                onRatingClick={this.handleChangeScore.bind(this)}
                isPhoneSize={isPhoneSize}
                subscriptionModal={this.subscriptionModal.bind(
                  this,
                  currentEpisodeItem
                )}
                buttons={
                  <Fragment>
                    {currentEpisodeItem &&
                      currentEpisodeItem.userHasAccess &&
                      currentEpisodeItem.seekTime > 0 && (
                        <div className={style.trailerLink}>
                          {!isPhoneSize && (
                            <Button
                              rootClassName={style.roundButtonWithMarginRight}
                              type={ButtonType.BORDERED}
                              icon={
                                <CatchupIcon className={style.trailerIcon} />
                              }
                              onClick={() =>
                                this.onRestartPlayer(currentEpisodeItem.getId())
                              }
                            >
                              <span
                                className={classnames(
                                  style.textButton,
                                  style.removeLineHeight
                                )}
                              >
                                <Trans>Restart</Trans>
                              </span>
                            </Button>
                          )}
                          {isPhoneSize && (
                            <div className={style.round}>
                              <Button
                                rootClassName={classnames(style.roundButton, {
                                  [style.roundButtonRTL]: direction === "rtl",
                                })}
                                type={ButtonType.BORDERED}
                                icon={
                                  <CatchupIcon className={style.trailerIcon} />
                                }
                                onClick={() =>
                                  this.onRestartPlayer(
                                    currentEpisodeItem.getId()
                                  )
                                }
                              />
                              <span
                                className={classnames(
                                  style.textButton,
                                  style.removeLineHeight
                                )}
                              >
                                <Trans>Restart</Trans>
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    <FavoriteToggleButton
                      onFavoriteClick={this.handleChangeFavoriteStatus.bind(
                        this
                      )}
                      isAssetFavorite={isAssetFavorite}
                      rootClassName={classnames(style.favoriteIcon, {
                        [style.roundButtonRTL]: direction === "rtl",
                      })}
                      isPhoneSize={isPhoneSize}
                    />
                    {TVShowTrailer && (
                      <div className={style.trailerLink}>
                        {!isPhoneSize && (
                          <Button
                            rootClassName={style.roundButton}
                            type={ButtonType.BORDERED}
                            icon={<TrailerIcon className={style.trailerIcon} />}
                            href={consts.routes.playerTrailer.url.replace(
                              ":assetId",
                              firstEpisodeID
                            )}
                          >
                            <span
                              className={classnames(
                                style.textButton,
                                style.removeLineHeight
                              )}
                            >
                              <Trans>Watch trailer</Trans>
                            </span>
                          </Button>
                        )}
                        {isPhoneSize && (
                          <div className={style.round}>
                            <Button
                              rootClassName={style.roundButton}
                              type={ButtonType.BORDERED}
                              icon={
                                <TrailerIcon className={style.trailerIcon} />
                              }
                              href={consts.routes.playerTrailer.url.replace(
                                ":assetId",
                                firstEpisodeID
                              )}
                            />
                            <span
                              className={classnames(
                                style.textButton,
                                style.removeLineHeight
                              )}
                            >
                              <Trans>Last trailer</Trans>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {consts.padlocks === true &&
                      isConnected === true &&
                      currentEpisodeItem &&
                      !currentEpisodeItem.userHasAccess && (
                        <Fragment>
                          {!isPhoneSize && (
                            <Button
                              rootClassName={style.subscriptionButton}
                              type={ButtonType.NORMAL}
                              icon={
                                <Subscription
                                  className={style.subscriptionIcon}
                                />
                              }
                              onClick={this.subscriptionModal.bind(
                                this,
                                currentEpisodeItem
                              )}
                            >
                              <span className={style.textButton}>
                                <Trans>Subscription options</Trans>
                              </span>
                            </Button>
                          )}
                          {isPhoneSize && (
                            <div className={style.round}>
                              <Button
                                rootClassName={style.roundButton}
                                type={ButtonType.NORMAL}
                                icon={
                                  <Subscription
                                    className={style.subscriptionIcon}
                                  />
                                }
                                onClick={this.subscriptionModal.bind(
                                  this,
                                  currentEpisodeItem
                                )}
                              />
                              <span className={style.textButton}>
                                <Trans>Subscription options</Trans>
                              </span>
                            </div>
                          )}
                        </Fragment>
                      )}
                  </Fragment>
                }
              />
              <div className={style.descriptionContainer}>
                <div className={style.synopsisContainer}>
                  <h2 className={style.containerTitle}>
                    <Trans>SYNOPSIS</Trans>
                  </h2>
                  <p className={style.containerInformation}>
                    {tvshow.getSynopsis()}
                  </p>
                </div>
                {TVShowCastingFirstRoles && TVShowCastingFirstRoles.length > 0 && (
                  <Fragment>
                    <div className={style.horizontalLine} />
                    <div className={style.castContainer}>
                      <Fragment>
                        <h2 className={style.containerTitle}>
                          <Trans>CAST</Trans>
                        </h2>
                        <div>
                          {TVShowCastingFirstRoles &&
                            TVShowCastingFirstRoles.map((item, index) => (
                              <CastLabel key={index}>{item}</CastLabel>
                            ))}
                        </div>
                      </Fragment>

                      {TVShowCastingSecondRoles && (
                        <div className={style.castContainer}>
                          {TVShowCastingSecondRoles}
                        </div>
                      )}
                    </div>
                  </Fragment>
                )}
                {TVShowDirector && !TVShowDirector.includes("N/A") && (
                  <Fragment>
                    <div className={style.horizontalLine} />
                    <div className={style.directorContainer}>
                      <h2 className={style.containerTitle}>
                        <Trans>DIRECTOR(S)</Trans>
                      </h2>
                      <p className={style.containerInformation}>
                        {TVShowDirector}
                      </p>
                    </div>
                  </Fragment>
                )}
                <Fragment>
                  <div className={style.horizontalLine} />
                  {seasonsFormatted && (
                    <div className={style.seasonsSelector}>
                      <Dropdown
                        label={seasonsFormatted[activeSeason]?.label}
                        dropdownIcon={true}
                        autoWidth={true}
                        handleClick={(filter) => {
                          let season = seasonsFormatted.filter((element) => {
                            return element.label == filter.label;
                          });
                          this.setState({ activeSeason: season[0]?.index });
                        }}
                        data={seasonsFormatted}
                        stayOpenOnSelect={true}
                      />
                    </div>
                  )}
                  <div className={style.seasonContainer}>
                    {seasons && (
                      <SeasonEpisode
                        season={seasons[activeSeason]}
                        onClickFavorite={this.handleChangeFavoriteStatus.bind(
                          this
                        )}
                        isPhoneSize={isPhoneSize}
                        flyImage={tvshow.flyImage}
                        subscriptionModal={this.subscriptionModal.bind(this)}
                      />
                    )}
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state, props) => {
    const tvShowId = props.match.params.id;
    const getActiveAssetsParam = { seekTimeFilter: false };

    return {
      isConnected: state.session.customerAuthToken !== undefined,
      isloading: state.content.tvshow.loading,
      viewport: state.ui.viewport,
      tvshow: TucanoSelectors.getTvShow(state, tvShowId),
      seasons: TucanoSelectors.getTvShowSeasons(state, tvShowId),
      activeAssets: TucanoSelectors.getActiveAssets(
        state,
        getActiveAssetsParam
      ),
      isAssetFavorite: TucanoSelectors.getTVshowAssetsFavorite(state, tvShowId),
      deviceAuthToken: state.session.deviceAuthToken !== undefined,
    };
  }),
  withTranslation()
)(PageAssetTVShow);
