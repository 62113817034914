import actionTypes from "../consts/actionTypes";

const initialState = {
  loading: true,
  data: undefined,
  trailer: { playing: false },
  testAsset: {
    loading: false,
    data: undefined,
  },
  buyAsset: {
    loading: false,
    data: undefined,
  },
  toRestartLive: false,
  toRestartAssets: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEST_ASSET_PENDING:
      return {
        ...state,
        testAsset: {
          loading: true,
        },
      };
    case actionTypes.SET_TRAILER_STATUS:
      return {
        ...state,
        trailer: {
          playing: action.payload,
        },
      };
    case actionTypes.BUY_ASSET_PENDING:
      return {
        ...state,
        buyAsset: {
          loading: true,
        },
      };
    case actionTypes.READ_ASSET_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TEST_ASSET_SUCCESS:
      return {
        ...state,
        testAsset: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.BUY_ASSET_SUCCESS:
      return {
        ...state,
        buyAsset: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.READ_ASSET_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let { toRestartAssets } = state;
      // eslint-disable-next-line no-case-declarations
      let seek = action.payload.seek;
      if (toRestartAssets) {
        toRestartAssets = false;
        seek = 0;
      }
      return {
        ...state,
        data: {
          seek,
          url: action.payload.url,
        },
      };
    case actionTypes.TEST_ASSET_ERROR:
      return {
        ...state,
        testAsset: {
          loading: false,
          data: undefined,
          error: action.payload,
        },
        errorTestAsset: action.payload,
      };
    case actionTypes.BUY_ASSET_ERROR:
      return {
        ...state,
        errorBuyAsset: action.payload,
      };
    case actionTypes.READ_ASSET_ERROR:
      return {
        ...state,
        errorReadAsset: action.payload,
      };
    case actionTypes.GET_VOD_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          drm: {
            content: action.payload.content,
            licParam: action.payload.licParam,
            url: action.payload.url,
            vendor:action.payload.vendor
          },
        },
      };
    case actionTypes.POST_CHANNEL_STREAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {
          url: action.payload.url,
        },
      };
    }
    case actionTypes.POST_CHANNEL_STREAM_ERROR: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case actionTypes.POST_ASK_LICENSE_WV_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          drm: {
            content: action.payload.content,
            licParam: action.payload.licParam,
            url: action.payload.url,
          },
        },
      };

    case actionTypes.RESET_PLAYER_INFOS:
      return initialState;
    case actionTypes.RESTART_PLAYER_LIVE:
      return {
        ...state,
        toRestartLive: true,
      };
    case actionTypes.RESTART_ASSETS_VIDEO:
      return {
        ...state,
        toRestartAssets: true,
      };
    case actionTypes.RESET_GLOBAL_STATE:
      return initialState;
    default:
      return state;
  }
};
