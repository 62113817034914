import Loader from "components/Loader";
import Player from "components/Player";
import PlayerErrorMessage from "components/PlayerErrorMessage";
import PlayerReact from "components/PlayerReact";
import consts from "consts/consts";
import { detect } from "detect-browser";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { generateDeviceId } from "helpers/utility";
import get from "lodash.get";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PagePlayerTrailer extends Component {
  state = {
    show: false,
  };

  async fetch() {
    const { dispatch, isConnected } = this.props;
    this.setState({ loadTrailer: true });
    if (isConnected) {
      try {
        await this.checkDevice();
      } catch (e) {
        return;
      }
      await this.props.dispatch(TucanoActions.loginWebPlayer());
    }
    const assetId = this.props.match.params.assetId;
    const assetData = await dispatch(
      TucanoActions.getAsset(assetId, getFromLocal("languageId"))
    );
    const trailerId = get(
      assetData,
      "items[0].directMetadata.AD_LITRAILE[0].idMedia",
      undefined
    );
    await dispatch(TucanoActions.getTrailerUrl(trailerId));
    this.setState({ showTrailer: true, loadTrailer: false });
  }

  async checkDevice() {
    // Get Device ID From LocalStorage
    let deviceId = getFromLocal(consts.storageKeys.device);
    let device = { id: deviceId, ...detect() };

    if (this.props.deviceAuthToken) {
      return device;
    }

    // Generate One
    deviceId =
      device.id &&
      device.id !== "" &&
      device.id !== null &&
      device.id !== undefined
        ? device.id
        : generateDeviceId();
    saveInLocal(consts.storageKeys.device, deviceId);

    // Add Device
    await this.props.dispatch(TucanoActions.addDevice(deviceId));

    if (!this.props.deviceAuthToken) {
      throw new Error("Cant Add Device");
    }

    //Get New device stored
    device = { id: deviceId, ...detect() };

    // Auth Device
    await this.props.dispatch(TucanoActions.authDevice(device));

    return device;
  }

  componentDidMount() {
    // The user can watch the trailer even if he's on discovery mode
    this.fetch();
    // if (this.props.isConnected) {
    //   this.fetch();
    // } else {
    //   this.props.history.push("?login=1");
    // }
  }

  componentWillUnmount() {
    //this.props.dispatch(TucanoActions.resetPlayerInfos());
  }

  render() {
    const {
      isConnected,
      isLoading,
      asset,
      trailer,
      t,
      previousRoute,
      viewport,
      trailerErrorMessage,
      trailerError,
    } = this.props;

    let providerVideo = "";
    if (trailer) {
      if (trailer.url && trailer.url.includes("youtube.com")) {
        providerVideo = "youtube";
      }
      if (trailer.url && trailer.url.includes("vimeo.com")) {
        providerVideo = "vimeo";
      }
    }

    return (
      <div className={style.root}>
        {isLoading && <Loader centered={true} />}
        {!isLoading && (
          <div className={style.root}>
            {trailer && trailer.url && (
              <Fragment>
                {providerVideo !== "" ? (
                  <PlayerReact
                    isTrailer={true}
                    previousRoute={previousRoute}
                    assetInfo={{
                      title: `${t("Trailer")} - ${asset.getTitle()}`,
                    }}
                    handleclickonback={() => {
                      this.props.history.replace(
                        previousRoute ? previousRoute : "/"
                      );
                    }}
                    url={trailer.url}
                  />
                ) : (
                  <Player
                    isConnected={isConnected}
                    // We don't need to pass this param
                    // isTrailer={true}
                    previousRoute={previousRoute}
                    assetInfo={{
                      title: `${t("Trailer")} - ${asset.getTitle()}`,
                    }}
                    handleclickonback={() => {
                      this.props.history.replace(
                        previousRoute ? previousRoute : "/"
                      );
                    }}
                    viewport={viewport}
                    url={trailer.url}
                  />
                )}
              </Fragment>
            )}
            {trailer && !trailer.url && (
              <PlayerErrorMessage
                closeAction={() => {
                  this.props.history.replace(
                    previousRoute ? previousRoute : "/"
                  );
                }}
              />
            )}
            {trailerError && (
              <PlayerErrorMessage
                closeAction={() => {
                  this.props.history.replace(
                    previousRoute ? previousRoute : "/"
                  );
                }}
                message={trailerErrorMessage}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect((state) => {
    return {
      isLoading: state.content.asset.trailer.loading,
      trailerErrorMessage: state.content.asset.trailer.message,
      trailerError: state.content.asset.trailer.error,
      asset: TucanoSelectors.getAsset(state),
      trailer: TucanoSelectors.getAssetTrailer(state),
      previousRoute: state.ui.previousRoute,
      viewport: state.ui.viewport,
      isConnected: state.session.customerAuthToken !== undefined,
      deviceAuthToken: state.session.deviceAuthToken !== undefined,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  })
)(PagePlayerTrailer);
