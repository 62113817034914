export default {
  // ACCOUNT
  login: "/proxy/login",
  // REFRESH TOKEN
  loginDevice: "/proxy/loginDevice",
  graphQlLogin: "/oauth/token",
  appleAuth: "/applesso/oauth/token",
  resendEmailConfirmation: "/proxy/resendAccountActivationEmail",
  facebookAuth: "/facebook/auth",
  googleAuth: "/googlesso/oauth/token",
  loginWebPlayer: "/proxy/loginWebPlayer",
  casAddDevice: "/proxy/casAddDevice",
  casAuth: "/proxy/casAuth",
  casAvailableDevice: "/proxy/casAvailableDevice",
  casRemoveDevice: "/proxy/casRemoveDevice",
  casDeviceCount: "/proxy/casDeviceCount",
  logout: "/proxy/logout",
  accountDetails: "/proxy/accountDetails",
  changeDetails: "/proxy/changeDetails",
  changeAddress: "/proxy/changeAddress",
  updatePassword: "/proxy/updatePassword",
  askResetPassword: "/proxy/askResetPassword",
  getPasswordPolicy: "/proxy/getPasswordPolicy",
  resetPassword: "/proxy/resetPassword",
  adultCode: "/proxy/adultCode",
  setAdultCode: "/proxy/setAdultCode",
  setPurchaseCode: "/proxy/setPurchaseCode",
  validateAdultCode: "/proxy/validateAdultCode",
  validateAccount: "/proxy/validateAccount",
  validatePurchaseCode: "/proxy/validatePurchaseCode",
  PurchaseCode: "/proxy/purchaseCode",
  favoriteAssets: "/proxy/favoriteAssets",
  addFavoriteAsset: "/proxy/addFavoriteAsset",
  removeFavoriteAsset: "/proxy/removeFavoriteAsset",
  changeScoreAsset: "/proxy/changeScoreAsset",
  listInvoices: "/proxy/listInvoices",
  msg: "/proxy/msg",
  profile: "/crm/profile",
  avatars: "/crm/profile/avatars",
  anonymizeMe: "/proxy/anonymizeMe",
  syncFromTve: "/proxy/syncFromTve",
  requestExportAccount: "/proxy/requestExportAccount",
  getExportAccount: "/proxy/getExportAccount",

  // login by pairing
  loginByPairing: "/proxy/addMaterialByPairing",

  // REFERENCES
  hierarchy: "/proxy/hierarchy",
  menuOrder: "/proxy/menuOrder",
  listChannels: "/proxy/listChannels",
  faqData: "/proxy/faqData",

  // CONTENT
  homeContent: "/proxy/homeContent",
  listContent: "/proxy/listContent",
  epg: "/proxy/epg",
  epgDetail: "/cms/epg/event",
  assets: "/proxy/assets",
  assetSuggest: "/proxy/assets",
  assetScore: "/proxy/assetScore",
  activeAssets: "/proxy/activeAssets",
  imgdata: "/proxy/imgdata",
  search: "/cms/search",
  readTrailer: "/proxy/readTrailer",
  monolistRecommendation: "/analytics/recommendation/monolist",
  multifacetRecommendation: "/analytics/recommendation/multifacet",
  favoriteChannels: "/proxy/favoriteChannels",
  addFavoriteChannel: "/proxy/setFavoriteChannels",

  // PLAYER
  testAsset: "/proxy/testAsset",
  buyAsset: "/proxy/buyAsset",
  readAsset: "/proxy/readAsset",
  getVodLicense: "/proxy/getVodLicense",
  seekAsset: "/proxy/seekAsset",

  channelStream: "/proxy/channelStream",
  askLicenseWV: "/proxy/askLicenseWV",
  askLicenseFP: "/proxy/askLicenseFP",

  // SUBSCRIPTION
  createAccount: "/proxy/createAccount",
  addProducts: "/proxy/addProducts",
  addOptions: "/proxy/addOptions",
  removeOptions: "/proxy/removeOptions",
  askPayment: "/proxy/askPayment",
  askChangeBankInformation: "/proxy/askChangeBankInformation",
  availableOffers: "/proxy/availableOffers",
  listOptions: "/proxy/listOptions",
  activeProducts: "/proxy/activeProducts",
  optionValidity: "/proxy/optionsValidity",
  listPaymentMethods: "/proxy/listPaymentMethods",
  executePayment: "/proxy/executePayment",
  getBillingDashboardAccess: "/proxy/getBillingDashboardAccess",
  checkOrderStatus: "/proxy/checkOrderStatus",
  validateVoucher: "/proxy/validateVoucher",
  listCountries: "/proxy/listCountries",
  document: "/proxy/document",
};

export const apiGatewayUrls = {
  // CONFIGURATION
  configuration: "/configuration/API_KEY/data/configuration.json",
};
