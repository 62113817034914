import moment from "moment-timezone";

import consts from "../consts/consts";

export function msToTime(duration) {
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  let formatted = `${minutes}MN`;
  if (hours > 0 && minutes < 9) {
    formatted = `0${formatted}`;
  }
  if (hours > 0) {
    formatted = `${hours}H${formatted}`;
  }
  return formatted;
}

export function convertDateToTimezone(date, lang) {
  let dateTimezone = moment(date, "YYYY-MM-DD hh:mm:ss");
  return dateTimezone
    .clone()
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .locale(lang == "ar" ? "ar-MA" : lang)
    .format(consts.timeCatchupFormat);
}
