import classnames from "classnames";
import FavoriteToggle from "components/FavoriteToggle";
import FreeToAir from "components/Icons/FreeToAir";
import PadlockLock from "components/Icons/PadlockLock";
import Image, { PlaceholderType } from "components/Image";
import ProgressBar from "components/ProgressBar";
import consts from "consts/consts";
import { format } from "date-fns";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";

class EpgInfos extends Component {
  isChannelFavorite = (id) => {
    const { channelFavorits } = this.props;
    const found =
      channelFavorits &&
      channelFavorits.find((channel) => channel.idChannel === id);
    return found !== undefined;
  };
  async handleChangeFavoriteChannelStatus() {
    const { dispatch, isConnected, channel } = this.props;
    let id = parseInt(channel.id);
    let channelFavorits = this.props.channelFavorits;
    const isChannelFavorite = this.isChannelFavorite(id);
    if (isConnected) {
      if (isChannelFavorite) {
        const newChannelList = channelFavorits.filter(
          (channel) => channel.idChannel !== id
        );
        await dispatch(TucanoActions.addFavoriteChannel(newChannelList));
      } else {
        let order;
        if (channelFavorits.length > 0) {
          order = channelFavorits[channelFavorits.length - 1].order + 1;
        } else {
          order = 1;
        }
        channelFavorits.push({ idChannel: id, order: order });
        await dispatch(TucanoActions.addFavoriteChannel(channelFavorits));
      }
    } else {
      this.props.history.push("?login=1");
    }
  }
  render() {
    const {
      showLock,
      isConnected,
      channel,
      epg,
      rootClassName,
      onRemote,
      showFavorite,
      ratioCircle,
    } = this.props;
    const hasValidEPG = epg && Object.keys(epg).length !== 0;
    return (
      <div className={classnames(style.root, rootClassName)}>
        <div className={style.contextInfos}>
          <div className={style.logoContainer}>
            <Image
              rootClassName={style.channel}
              src={channel.getImage(consts.image_hostname, {
                width: 171,
                height: 171,
                languageId: getFromLocal("languageId"),
              })}
              height={70}
              width={70}
              placeholder={PlaceholderType.TRANSPARENT}
            />
          </div>
          <div className={style.epgContainer}>
            {consts.padlocks === true &&
            showLock &&
            isConnected &&
            !channel.userHasAccess ? (
              <div className={style.padlockContainer}>
                <div className={style.padlockIconContainer}>
                  <PadlockLock className={style.padlockLockIcon} />
                </div>
              </div>
            ) : (
              <p className={style.channelId}>{channel.getLocalizeNumber()}</p>
            )}
            <p className={style.channelName}>{channel.getName()}</p>
            {showFavorite && (
              <FavoriteToggle
                onFavoriteClick={this.handleChangeFavoriteChannelStatus.bind(
                  this
                )}
                isAssetFavorite={this.isChannelFavorite(parseInt(channel.id))}
                rootClassName={classnames(style.favoriteIcon, {
                  [style.favoriteIconCircle]: ratioCircle,
                })}
              />
            )}
            {hasValidEPG && (
              <Fragment>
                <div className={style.epgTime}>
                  {`${format(epg.getStartTime(), "HH:mm")} - ${format(
                    epg.getEndTime(),
                    "HH:mm"
                  )}`}
                  <span className={style.duration}>
                    ({epg.getHumanDuration()})
                  </span>
                </div>
                <div className={style.epgTitle}>{epg.getTitle()}</div>
                {channel.fta && !isConnected && onRemote && (
                  <div className={style.ftaIconContainer}>
                    <FreeToAir className={style.fta} />
                  </div>
                )}
                {epg.isLive() &&
                  (onRemote ? (
                    <ProgressBar
                      position={epg.getProgress()}
                      rootClassName={style.progressBarOnRemote}
                    />
                  ) : (
                    <ProgressBar
                      position={epg.getProgress()}
                      rootClassName={style.progressBar}
                    />
                  ))}
              </Fragment>
            )}
            {!hasValidEPG && (
              <div className={style.epgTitle}>
                {channel.getSynopsis() ? (
                  <div className={style.epgTitle}>{channel.getSynopsis()}</div>
                ) : (
                  <Trans>No information available</Trans>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default compose(
  connect((state, _props) => {
    const channelFavorits = state.content.favoritesChannels.data;
    return {
      channelFavorits,
      isConnected: state.session.customerAuthToken !== undefined,
    };
  })
)(EpgInfos);
EpgInfos.defaultProps = {
  epg: {},
  channel: {},
  isConnected: false,
  showLock: true,
  onRemote: false,
  rootClassName: null,
};

EpgInfos.propTypes = {
  epg: PropTypes.object,
  channel: PropTypes.object,
  rootClassName: PropTypes.object,
  isConnected: PropTypes.bool,
  showLock: PropTypes.bool,
};
